import { request } from '@/util/http'

export const getAll = () => {
  return request({
    url: '/mall/goodsname/all',
    method: 'get'
  })
}

export const getDetail = (id) => {
  return request({
    url: '/mall/goodsname/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const getImg = (name) => {
  return request({
    url: '/mall/goodsname/img',
    method: 'get',
    params: {
      name
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/mall/goodsname/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/mall/goodsname/submit',
    method: 'post',
    data: row
  })
}
export const updateTypeIds = (data) => {
  return request({
    url: '/mall/goodsname/updateTypeIds',
    method: 'post',
    data: data
  })
}

export const getNames = (query) => {
  return request({
    url: '/mall/goodsname/list',
    method: 'get',
    params: {
      query
    }
  })
}
