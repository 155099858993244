<template>
  <basic-container>
    <el-row :gutter="40">
      <el-col :span="12">
        <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            v-model="form"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-row-change="handleCurrentRowChange"
            @on-load="onLoad">
          <template slot="menuLeft">
            <div style="width: 200px" class="space-between">
              <span>
              共计：{{ total }}笔
              </span>
              <span>
              {{ sum }}元
              </span>
            </div>
          </template>
          <template slot-scope="{row}" slot="menu">
            <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                @click.stop="toDetail(row)">明细
            </el-button>
          </template>
          <template slot="dateRangeSearch">
            <el-date-picker
                v-model="search.dateRange"
                type="daterange"
                value-format="yyyy-MM-dd"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
            </el-date-picker>
          </template>
          <template slot="tradeNameSearch">
            <el-select
                v-model="search.tradeName"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="filterName"
                :loading="loadingNames">
              <el-option
                  v-for="item in names"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </template>
        </avue-crud>
      </el-col>
      <el-col :span="12">
        <el-col :span="24">
          <span class="title"><i class="el-icon-s-flag"></i>基本信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-form
            :model="order"
            ref="order"
            label-position="left"
            label-width="120px"
            class="small-space">
          <el-col :span="12">
            <el-form-item label="订单编号：" prop="orderSn">
              <span>{{ order.orderSn }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订单金额：" prop="totalAmount">
              <span>{{ (order.totalAmount || 0).toFixed(2) }}元</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="优惠金额：" prop="payAmount">
              <span>{{ ((order.totalAmount - order.payAmount) || 0).toFixed(2) }}元</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应收金额：" prop="payAmount">
              <span>{{ (order.payAmount || 0).toFixed(2) }}元</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付时间：" prop="paymentTime">
              <span>{{ order.paymentTime }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付类型：" prop="paymentTime">
              <span>{{ order.payTypeStr }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="抹零单位：" prop="singleResetUnitStr">
              <span>{{ order.singleResetUnitStr }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="抹零方式：" prop="singleResetTypeStr">
              <span>{{ order.singleResetTypeStr }}</span>
            </el-form-item>
          </el-col>
        </el-form>
        <el-col :span="24">
          <span class="title"><i class="el-icon-s-flag"></i>明细列表</span>
          <el-divider></el-divider>
        </el-col>
        <el-table
            class="good-table"
            :data="order.orderItemList"
            border
            fit>
          <el-table-column label="商品主图" prop="goodsPic">
            <template slot-scope="scope">
              <img v-if="scope.row.goodsPic" :src=" scope.row.goodsPic" alt="" width="48" height="48">
            </template>
          </el-table-column>
          <el-table-column label="商品名称" prop="goodsName">
            <template slot-scope="scope">
              <span>{{ scope.row.goodsName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="重量/数量" prop="weight">
            <template slot-scope="scope">
              <span>{{ scope.row.amountStr }}</span>
            </template>
          </el-table-column>
          <el-table-column label="价格" prop="price">
            <template slot-scope="scope">
              <span>{{ scope.row.price }} 元</span>
            </template>
          </el-table-column>
          <el-table-column label="小计" prop="price">
            <template slot-scope="scope">
              <span>{{ scope.row.payAmount }} 元</span>
            </template>
          </el-table-column>
        </el-table>
        <el-col :span="24" class="end">
          <el-button type="text" style="float: left;font-size: 15px;" @click="printOrder">打印小票</el-button>
        </el-col>
      </el-col>

    </el-row>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getOrderInfo, getOrderList, groupName } from '@/api/mall/stat'
import { getNames } from '@/api/mall/goodsname'
import { getToken } from '@/util/auth'
export default {
  mixins: [mixin],
  data () {
    return {
      form: {},
      search: {},
      page: {
        pageSize: 15,
        currentPage: 1,
        total: 0
      },
      sum: null,
      total: null,
      selectionList: [],
      option: {
        highlightCurrentRow: true,
        columnBtn: false,
        refreshBtn: false,
        addBtn: false,
        menu: false,
        labelWidth: 200,
        column: [
          {
            label: '订单编号',
            search: true,
            searchSpan: 12,
            prop: 'orderSn'
          },
          {
            label: '商品类别',
            prop: 'typeId',
            type: 'select',
            search: true,
            searchSpan: 12,
            dicUrl: '/mall/goodstype/dict',
            hide: true
          },
          {
            label: '商品学名',
            prop: 'tradeName',
            searchSlot: true,
            searchSpan: 12,
            search: true,
            hide: true
          },
          {
            label: '时间范围',
            prop: 'dateRange',
            search: true,
            searchSpan: 12,
            searchSlot: true,
            hide: true
          },

          {
            label: '订单总金额',
            prop: 'totalAmount'
          },
          {
            label: '实际支付金额',
            prop: 'payAmount'
          },
          {
            label: '支付方式',
            prop: 'payType',
            type: 'select',
            dicUrl: '/sys/dict/code/order_pay_type'
          },
          {
            label: '订单状态',
            prop: 'status',
            type: 'select',
            dicUrl: '/sys/dict/code/order_status'
          },
          {
            label: '创建时间',
            prop: 'createTime'
          }
        ]
      },
      loadingNames: false,
      names: [],
      data: [],
      order: {},
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '昨日',
            onClick (picker) {
              const yesterday = new Date(new Date() - 1000 * 60 * 60 * 24)
              picker.$emit('pick', [yesterday, yesterday])
            }
          }, {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }]
      }
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    printOrder () {
      window.app.printOrder(JSON.stringify(this.order))
    },
    loadList () {
      groupName().then(res => {
        console.log(res)
      })
    },
    filterName (query) {
      if (query !== '') {
        this.loadingNames = true
        getNames(query).then(res => {
          if (res.success) {
            this.loadingNames = false
            this.names = res.data
          } else {
            if (res.code === 4103) {
              window.app.refreshToken(getToken())
            }
          }
        })
      } else {
        this.names = []
      }
    },
    searchReset () {
      this.search = {}
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.search = params
      if (this.search.dateRange && this.search.dateRange.length === 2) {
        this.search.start = this.search.dateRange[0]
        this.search.end = this.search.dateRange[1]
      }
      console.log(JSON.stringify(this.search))
      this.onLoad(this.page)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange () {
      this.onLoad(this.page)
    },
    currentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    onLoad (page) {
      getOrderList(page.currentPage, page.pageSize, this.search).then(res => {
        if (res.success) {
          const data = res.data
          this.page.total = data.total
          this.data = data.records
          this.sum = data.sum
          this.total = data.total
        } else {
          if (res.code === 4103) {
            window.app.refreshToken(getToken())
          }
        }
      })
    },
    handleCurrentRowChange (row) {
      getOrderInfo(row.id).then(res => {
        this.order = res.data
      })
    },
    toDetail (row) {
      this.$router.push({
        path: '/mall/orderitem',
        query: { orderId: row.id }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  i {
    margin-right: 15px;
  }
  font-size: 24px;
}

</style>
